import { $feathers } from '../../plugins/feathers'
import { inArray } from '../../helpers/functions'

export const chapelService = $feathers.service('chapel/schedule')
export const studentService = $feathers.service('chapel/student')
export const summaryService = $feathers.service('chapel/summary')

export const sports = [
  { text: 'Baseball', value: 'BASEBALL' },
  { text: 'JV Baseball', value: 'JVBASEBA' },
  { text: 'Basketball', value: 'BASKET' },
  { text: 'JV Basketball', value: 'JVBASKET' },
  { text: 'Cross Country', value: 'CROSSCOU' },
  { text: 'Golf', value: 'GOLF' },
  { text: 'Soccer', value: 'SOCCER' },
  { text: 'JV Soccer', value: 'JVSOCCER' },
  { text: 'Softball', value: 'SOFTBALL' },
  { text: 'JV Softball', value: 'JVSOFTBA' },
  { text: 'Tennis', value: 'TENNIS' },
  { text: 'Track and Field', value: 'TRACK' },
  { text: 'Volleyball', value: 'VOLLEY' },
  { text: 'JV Volleyball', value: 'JVVOLLEY' }
]

export async function updateCredit (chapelId, studentId, type, reason, override) {
  // Load the chapel record
  let chapel = await chapelService.get(chapelId)
  // Determine the chapel credit value being given
  let credit = 1
  if ('credits' in chapel && type in chapel.credits) {
    credit = chapel.credits[type]
  } else {
    await chapelService.patch(chapelId, { ['credits.' + type]: 1 })
  }
  // Load the student record
  let student = await studentService.get(studentId)
  // Check the student credits to make sure they don't already have credit
  let hasCredit = false
  if ('credits' in student && Array.isArray(student.credits) && student.credits.length > 0) {
    for (let i = 0; i < student.credits.length; i++) {
      if (student.credits[i].chapel === chapelId) {
        // Student already has credit for this chapel; see if the type of credit is in the list of types to override
        hasCredit = true
        // Check to see if the credit group is in the override array
        if (inArray(student.credits[i].type, override)) {
          // Update that entry to the new group
          await studentService.patch(studentId, {
            ['credits.' + i + '.credit']: credit,
            ['credits.' + i + '.submitted']: new Date(),
            ['credits.' + i + '.type']: type,
            ['credits.' + i + '.reason']: reason
          })
          return true
        }
        break
      }
    }
  }
  // If the student does not have the credit, push the new credit into the student's credits list
  if (!hasCredit) {
    await studentService.patch(studentId, { $push: { credits: { credit, chapel: chapelId, submitted: new Date(), type, reason } } })
    return true
  }
  return false
}

export function getTermText (term) {
  return (term.substring(5, 2) === '05' ? 'Spring ' : 'Fall ') + term.substring(0, 4)
}

// Find all students who have the given credit type for the given chapel and update their value to the given value
export async function updateStudentCredits ({ chapelId, type, value }) {
  const aggregate = [
    { $match: { credits: { $elemMatch: { chapel: chapelId, type } } } },
    { $unwind: { path: '$credits', includeArrayIndex: 'index' } },
    { $match: { 'credits.chapel': chapelId, 'credits.type': type } }
  ]
  const { data } = await studentService.find({ query: { aggregate } })
  for (const { _id, index, credits: { credit } } of data) {
    if (credit !== value) {
      await studentService.patch(_id, { ['credits.' + index + '.credit']: value })
    }
  }
}
