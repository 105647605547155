<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>Chapel Students{{ chapelText }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog v-model="showFilters" width="500">
        <template v-slot:activator="{ on: dialogOn }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltipOn }">
              <v-btn text v-on="{ ...dialogOn, ...tooltipOn }">
                <v-icon left>{{ hasFilters ? 'fas fa-filter' : 'fal fa-filter' }}</v-icon>
                Filters
              </v-btn>
            </template>
            <span>{{ showFilters ? 'Hide' : 'Show' }} Filters</span>
          </v-tooltip>
        </template>
        <v-card>
          <v-toolbar class="mb-4">
            <v-toolbar-title>Student Filters</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="showFilters = false">
              <v-icon>fal fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="nameFilter" label="Name" outlined hide-details></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete v-model="hallFilter" :items="hallOptions" label="Hall" multiple outlined hide-details>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="hallFilter.length === 1" small>{{ item.text }}</v-chip>
                    <span v-else-if="hallFilter.length > 1 && index === 0" class="grey--text text-caption">{{ hallFilter.length }} Selected</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="classFilter" :items="classOptions" label="Class Level" multiple outlined hide-details>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="classFilter.length === 1" small>{{ item.text }}</v-chip>
                    <span v-else-if="classFilter.length > 1 && index === 0" class="grey--text text-caption">{{ classFilter.length }} Selected</span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete v-model="sportFilter" :items="sportOptions" label="Sports" multiple outlined hide-details>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="sportFilter.length === 1" small>{{ item.text }}</v-chip>
                    <span v-else-if="sportFilter.length > 1 && index === 0" class="grey--text text-caption">{{ sportFilter.length }} Selected</span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="genderFilter" :items="genderOptions" label="Gender" outlined hide-details></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="fullPartTimeFilter" :items="fullPartTimeOptions" label="Full/Part Time" outlined hide-details></v-select>
              </v-col>
              <v-col v-if="chapelText === ''" cols="12" md="6">
                <v-text-field v-model="remainingMinFilter" :hint="'Chapels Remaining: ' + remainingChapels" label="Remaining Min" type="number" persistent-hint outlined></v-text-field>
              </v-col>
              <v-col v-if="chapelText === ''" cols="12" md="6">
                <v-text-field v-model="remainingMaxFilter" :hint="'Chapels Remaining: ' + remainingChapels" label="Remaining Max" type="number" persistent-hint outlined></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="showFilters = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-menu v-if="chapelText === ''" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            {{ termText }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list style="max-height:300px;overflow:auto">
          <v-list-item v-for="item in termOptions" :key="item.value" @click="term = item.value">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <requirement-setup :term="term" @updated="loadStudents()"></requirement-setup>
      <grace-credit :term="term" :students="filteredList"></grace-credit>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon>fal fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="exportToExcel()">
            <v-list-item-avatar>
              <v-icon>fal fa-file-excel</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Export Current List</v-list-item-title>
              <v-list-item-subtitle>Exports the current list to Excel</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <email :data="filteredList" :merge-fields="emailMergeFields" from="report-email@covenant.edu">
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-avatar>
                  <v-icon>fal fa-envelope</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Email Current List</v-list-item-title>
                  <v-list-item-subtitle>Send an email to the students in the current list</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </email>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-data-table :items="filteredList" :headers="headers" @update:options="updateOptions" @dblclick:row="(e, { item }) => viewStudent(item)">
      <!-- eslint-disable-next-line vue/valid-v-slot -->
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on" :to="'/chapel/student/' + item.bannerId + '/' + term">
              <v-icon>fal fa-eye</v-icon>
            </v-btn>
          </template>
          <span>View/Edit Credits Received</span>
        </v-tooltip>
      </template>
      <template v-slot:item.date="{ item }">{{ stringFormatDate(item.date) }}</template>
    </v-data-table>
  </v-card>
</template>
<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { sports } from '@/components/chapel/functions'
import { stringFormatDate } from '@/helpers/formatters'
import { saveAs } from 'file-saver'

export default {
  components: {
    RequirementSetup: () => import('@/components/chapel/requirementSetup'),
    GraceCredit: () => import('@/components/chapel/graceCredit'),
    Email: () => import('@/components/system/emailDialog')
  },
  setup (props, { root }) {
    const chapelId = computed(() => root.$route.params.id || null)
    const creditType = computed(() => root.$route.params.type || null)
    const chapelText = ref('')
    const term = computed({
      get: () => root.$store.state.chapel.studentTerm,
      set: (term) => {
        root.$store.commit('chapel/setStudentTerm', term)
      }
    })
    const termOptions = computed(() => root.$store.state.chapel.studentTermOptions)
    const termText = computed(() => (term.value === '' ? 'Select Term' : (term.value.substring(4) === '05' ? 'Spring ' : 'Fall ') + term.value.substring(0, 4)))
    const termSearchActive = ref(false)
    const termSearchRef = ref(null)

    function openTermSearch () {
      termSearchActive.value = true
      setTimeout(() => { termSearchRef.value.focus() }, 100)
    }

    onMounted(async () => {
      if (root.$store.state.chapel.studentTermOptions.length === 0) {
        await root.$store.dispatch('chapel/loadOptions')
        if (termOptions.value.length > 0) {
          console.log(termOptions.value[0])
          root.$store.commit('chapel/setStudentTerm', termOptions.value[0].value)
        } else {
          console.log('termOptions is empty')
        }
      }
    })

    const remainingChapels = ref(0)

    watch([term, termOptions], () => {
      if (term.value !== '' && termOptions.value.length > 0) {
        for (let i = 0; i < termOptions.value.length; i++) {
          if (termOptions.value[i].code === term.value) termText.value = termOptions.value[i].text
        }
        root.$feathers.service('chapel/schedule').find({ query: { term: term.value, date: { $gt: new Date() }, $limit: 0 } }).then(({ total }) => {
          remainingChapels.value = total
        })
      }
    })

    const students = ref([])
    const totalCount = ref(0)
    const headers = computed(() => {
      const arr = [
        { text: 'Banner ID', value: 'bannerId', exportWidth: 11 },
        { text: 'Name', value: 'name', exportWidth: 20 },
        { text: 'Hall', value: 'hall', exportWidth: 20 },
        { text: 'Class', value: 'classLevel', exportWidth: 5 }
      ]
      if (chapelId.value) {
        if (creditType.value == null) {
          arr.push({ text: 'Credit Type', value: 'type', exportWidth: 10 })
        }
        arr.push({ text: 'Reason', value: 'reason', exportWidth: 30 })
        arr.push({ text: 'Date', value: 'date', exportWidth: 13 })
      } else {
        arr.push({ text: 'Required', value: 'required', exportWidth: 10 })
        arr.push({ text: 'Received', value: 'received', exportWidth: 10 })
        arr.push({ text: 'Remaining', value: 'remaining', exportWidth: 10 })
        arr.push({ text: '', value: 'actions' })
      }
      return arr
    })
    const emailMergeFields = computed(() => {
      const arr = headers.value.filter(({ text }) => text !== '').map(({ text, value }) => { return { label: text, value } })
      arr.splice(1, 1, { label: 'First', value: 'first' })
      arr.splice(2, 0, { label: 'Last', value: 'last' })
      return arr
    })
    const options = computed(() => root.$store.state.chapel.studentResultOptions)

    function updateOptions (options) {
      root.$store.commit('chapel/setStudentResultOptions', options)
      // loadStudents()
    }

    const showFilters = ref(false)
    const nameFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.name,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'name', filter: val })
    })
    const hallFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.hall,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'hall', filter: val })
    })
    const classFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.classLevel,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'classLevel', filter: val })
    })
    const sportFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.sport,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'sport', filter: val })
    })
    const genderFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.gender,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'gender', filter: val })
    })
    const remainingMinFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.remainingMin,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'remainingMin', filter: val })
    })
    const remainingMaxFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.remainingMax,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'remainingMax', filter: val })
    })
    const fullPartTimeFilter = computed({
      get: () => root.$store.state.chapel.studentFilter.fullPartTime,
      set: (val) => root.$store.commit('chapel/setStudentFilterField', { field: 'fullPartTime', filter: val })
    })
    const hasFilters = computed(() => {
      for (const field in root.$store.state.chapel.studentFilter) {
        if (typeof (root.$store.state.chapel.studentFilter[field]) === 'object') {
          if (root.$store.state.chapel.studentFilter[field].length > 0) return true
        } else if (root.$store.state.chapel.studentFilter[field] !== '') {
          return true
        }
      }
    })
    const fullPartTimeOptions = ref([{ text: 'All', value: '' }, { text: 'Full Time', value: 'F' }, { text: 'Part Time', value: 'P' }])
    const hallOptions = computed(() => root.$store.state.chapel.hallOptions)
    const classOptions = ref([
      { text: 'Freshmen', value: 'FR' },
      { text: 'Sophomore', value: 'SO' },
      { text: 'Junior', value: 'JR' },
      { text: 'Senior', value: 'SR' }
    ])
    const sportOptions = ref(sports)
    const genderOptions = ref([
      { text: 'All', value: '' },
      { text: 'Female', value: 'F' },
      { text: 'Male', value: 'M' }
    ])
    watch(term, loadStudents)
    watch(chapelId, async () => {
      if (chapelId.value != null) {
        try {
          const data = await root.$feathers.service('chapel/schedule').get(chapelId.value)
          let str = stringFormatDate(data.date, true)
          if (str.substring(21, 26) === '11:00') str = str.substring(0, 17)
          if (creditType.value) str += ' - ' + creditType.value + ' Credits'
          chapelText.value = ' - ' + str
          term.value = data.term
        } catch (e) {
          root.$store.dispatch('main/snackbar', { type: 'error', text: 'Error looking up chapel: ' + e })
        }
        await loadStudents()
      }
    })
    async function loadStudents () {
      students.value = []
      const aggregate = []
      if (chapelId.value != null && chapelId.value !== '') {
        const $match = {}
        if (creditType.value != null && creditType.value !== '') {
          $match.credits = { $elemMatch: { chapel: chapelId.value, type: creditType.value } }
        } else {
          $match['credits.chapel'] = chapelId.value
        }
        aggregate.push({ $match })
        aggregate.push({ $unwind: '$credits' })
        if (creditType.value != null && creditType.value !== '') {
          aggregate.push({ $match: { 'credits.chapel': chapelId.value, 'credits.type': creditType.value } })
        } else {
          aggregate.push({ $match: { 'credits.chapel': chapelId.value } })
        }
        aggregate.push({ $lookup: { from: 'Directory', localField: 'bannerId', foreignField: 'bannerId', as: 'directory' } })
        aggregate.push({
          $project: {
            bannerId: 1,
            name: { $concat: ['$name.last', ', ', '$name.preferred'] },
            last: '$name.last',
            first: '$name.preferred',
            gender: '$bio.gender',
            hall: '$housing.hall',
            classLevel: 1,
            fullPartTime: 1,
            sports: 1,
            type: '$credits.type',
            reason: '$credits.reason',
            date: '$credits.date',
            directory: { $first: '$directory' }
          }
        })
        aggregate.push({
          $project: {
            bannerId: 1,
            name: 1,
            last: 1,
            first: 1,
            email: '$directory.email',
            gender: 1,
            hall: 1,
            classLevel: 1,
            fullPartTime: 1,
            sports: 1,
            type: 1,
            reason: 1,
            date: 1
          }
        })
      } else {
        aggregate.push({ $match: { term: term.value } })
        aggregate.push({ $lookup: { from: 'Directory', localField: 'bannerId', foreignField: 'bannerId', as: 'directory' } })
        aggregate.push({
          $project: {
            bannerId: 1,
            name: { $concat: ['$name.last', ', ', '$name.preferred'] },
            last: '$name.last',
            first: '$name.preferred',
            gender: '$bio.gender',
            hall: '$housing.hall',
            classLevel: 1,
            fullPartTime: 1,
            sports: 1,
            directory: { $first: '$directory' },
            required: '$required',
            received: { $reduce: { input: '$credits', initialValue: 0, 'in': { $sum: '$credits.credit' } } }
          }
        })
        aggregate.push({
          $project: {
            bannerId: 1,
            name: 1,
            last: 1,
            first: 1,
            email: '$directory.email',
            gender: 1,
            hall: 1,
            classLevel: 1,
            fullPartTime: 1,
            sports: 1,
            required: 1,
            received: 1,
            remaining: { $max: [ 0, { $subtract: ['$required', '$received'] } ] }
          }
        })
      }
      if (aggregate.length > 0) {
        const { data } = await root.$feathers.service('chapel/student').find({ query: { aggregate } })
        students.value = data
      }
    }
    const filteredList = computed(() => students.value.filter(({ name, hall, classLevel, sports, gender, remaining, fullPartTime }) => {
      if (nameFilter.value != null && nameFilter.value.trim() !== '') {
        let text = nameFilter.value.trim()
        if (text.search(', ') >= 0) {
          text = text.replace(', ', '[a-z]*, [a-z]*')
        } else if (text.search(',') >= 0) {
          text = text.replace(',', '[a-z]*, [a-z]*')
        } else if (text.search(' ') >= 0) {
          const temp = text.split(' ')
          text = temp[1] + '[a-z]*,[ ]*[a-z]*' + temp[0]
        }
        const regex = new RegExp(text, 'ig')
        if (!regex.test(name)) return false
      }
      if (hallFilter.value.length > 0) {
        if (!hallFilter.value.includes(hall)) return false
      }
      if (classFilter.value.length > 0) {
        if (!classFilter.value.includes(classLevel)) return false
      }
      if (sportFilter.value.length > 0) {
        if (sports == null) return false
        if (sportFilter.value.filter((val) => sports.includes(val)).length === 0) return false
      }
      if (genderFilter.value !== '') {
        if (gender !== genderFilter.value) return false
      }
      if (remainingMinFilter.value !== '') {
        const val = parseInt(remainingMinFilter.value)
        if (!isNaN(val)) {
          if (remaining < val) return false
        }
      }
      if (remainingMaxFilter.value !== '') {
        const val = parseInt(remainingMaxFilter.value)
        if (!isNaN(val)) {
          if (remaining > val) return false
        }
      }
      if (fullPartTimeFilter.value !== '' && fullPartTimeFilter.value !== fullPartTime) {
        return false
      }
      return true
    }))

    function changeRequirements (item) {}
    function viewSchedule (item) {}

    function viewStudent (item) {
      console.log(item)
      console.log(term.value)
      root.$router.push('/chapel/student/' + item.bannerId + '/' + term.value)
    }

    async function exportToExcel () {
      const arr = [ ...headers.value ]
      arr.splice(4, 0, { text: 'Sports', value: 'sports', exportWidth: 20 })
      arr.splice(2, 0, { text: 'Email Address', value: 'email', exportWidth: 30 })
      const header = arr.filter((text) => text !== '').map(({ text: header, value: key, exportWidth: width }) => { return { header, align: (header === 'Required' || header === 'Received' || header === 'Remaining' ? 'right' : 'left'), key, width } })
      let filename = 'Chapel Student Export.xlsx'
      root.$feathers.service('export/xlsx').create({ filename, header, rows: filteredList.value.map((row) => { return { ...row, sports: row.sports ? row.sports.join('; ') : '' } }) }).then((data) => {
        saveAs(new Blob([data.buffer]), filename)
      })
    }

    return {
      chapelId,
      creditType,
      chapelText,
      term,
      termOptions,
      termText,
      termSearchActive,
      termSearchRef,
      openTermSearch,
      remainingChapels,
      students,
      totalCount,
      headers,
      emailMergeFields,
      options,
      updateOptions,
      filteredList,
      loadStudents,
      showFilters,
      nameFilter,
      hallFilter,
      classFilter,
      sportFilter,
      genderFilter,
      remainingMinFilter,
      remainingMaxFilter,
      fullPartTimeFilter,
      hasFilters,
      fullPartTimeOptions,
      hallOptions,
      classOptions,
      sportOptions,
      genderOptions,
      changeRequirements,
      viewSchedule,
      viewStudent,
      stringFormatDate,
      exportToExcel
    }
  }
}
</script>
