var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4"},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Chapel Students"+_vm._s(_vm.chapelText))]),_c('v-spacer'),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialogOn = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},Object.assign({}, dialogOn, tooltipOn)),[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.hasFilters ? 'fas fa-filter' : 'fal fa-filter'))]),_vm._v(" Filters ")],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.showFilters ? 'Hide' : 'Show')+" Filters")])])]}}]),model:{value:(_vm.showFilters),callback:function ($$v) {_vm.showFilters=$$v},expression:"showFilters"}},[_c('v-card',[_c('v-toolbar',{staticClass:"mb-4"},[_c('v-toolbar-title',[_vm._v("Student Filters")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showFilters = false}}},[_c('v-icon',[_vm._v("fal fa-times")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Name","outlined":"","hide-details":""},model:{value:(_vm.nameFilter),callback:function ($$v) {_vm.nameFilter=$$v},expression:"nameFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.hallOptions,"label":"Hall","multiple":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.hallFilter.length === 1)?_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.text))]):(_vm.hallFilter.length > 1 && index === 0)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(_vm._s(_vm.hallFilter.length)+" Selected")]):_vm._e()]}}]),model:{value:(_vm.hallFilter),callback:function ($$v) {_vm.hallFilter=$$v},expression:"hallFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.classOptions,"label":"Class Level","multiple":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.classFilter.length === 1)?_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.text))]):(_vm.classFilter.length > 1 && index === 0)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(_vm._s(_vm.classFilter.length)+" Selected")]):_vm._e()]}}]),model:{value:(_vm.classFilter),callback:function ($$v) {_vm.classFilter=$$v},expression:"classFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.sportOptions,"label":"Sports","multiple":"","outlined":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.sportFilter.length === 1)?_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.text))]):(_vm.sportFilter.length > 1 && index === 0)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(_vm._s(_vm.sportFilter.length)+" Selected")]):_vm._e()]}}]),model:{value:(_vm.sportFilter),callback:function ($$v) {_vm.sportFilter=$$v},expression:"sportFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.genderOptions,"label":"Gender","outlined":"","hide-details":""},model:{value:(_vm.genderFilter),callback:function ($$v) {_vm.genderFilter=$$v},expression:"genderFilter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.fullPartTimeOptions,"label":"Full/Part Time","outlined":"","hide-details":""},model:{value:(_vm.fullPartTimeFilter),callback:function ($$v) {_vm.fullPartTimeFilter=$$v},expression:"fullPartTimeFilter"}})],1),(_vm.chapelText === '')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hint":'Chapels Remaining: ' + _vm.remainingChapels,"label":"Remaining Min","type":"number","persistent-hint":"","outlined":""},model:{value:(_vm.remainingMinFilter),callback:function ($$v) {_vm.remainingMinFilter=$$v},expression:"remainingMinFilter"}})],1):_vm._e(),(_vm.chapelText === '')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"hint":'Chapels Remaining: ' + _vm.remainingChapels,"label":"Remaining Max","type":"number","persistent-hint":"","outlined":""},model:{value:(_vm.remainingMaxFilter),callback:function ($$v) {_vm.remainingMaxFilter=$$v},expression:"remainingMaxFilter"}})],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.showFilters = false}}},[_vm._v("Close")])],1)],1)],1),(_vm.chapelText === '')?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.termText)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}],null,false,2012670719)},[_c('v-list',{staticStyle:{"max-height":"300px","overflow":"auto"}},_vm._l((_vm.termOptions),function(item){return _c('v-list-item',{key:item.value,on:{"click":function($event){_vm.term = item.value}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1):_vm._e(),_c('requirement-setup',{attrs:{"term":_vm.term},on:{"updated":function($event){return _vm.loadStudents()}}}),_c('grace-credit',{attrs:{"term":_vm.term,"students":_vm.filteredList}}),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-ellipsis-v")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.exportToExcel()}}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("fal fa-file-excel")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Export Current List")]),_c('v-list-item-subtitle',[_vm._v("Exports the current list to Excel")])],1)],1),_c('email',{attrs:{"data":_vm.filteredList,"merge-fields":_vm.emailMergeFields,"from":"report-email@covenant.edu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({},on),[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("fal fa-envelope")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Email Current List")]),_c('v-list-item-subtitle',[_vm._v("Send an email to the students in the current list")])],1)],1)]}}])})],1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.filteredList,"headers":_vm.headers},on:{"update:options":_vm.updateOptions,"dblclick:row":function (e, ref) {
	var item = ref.item;

	return _vm.viewStudent(item);
}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":'/chapel/student/' + item.bannerId + '/' + _vm.term}},on),[_c('v-icon',[_vm._v("fal fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View/Edit Credits Received")])])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.stringFormatDate(item.date)))]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }